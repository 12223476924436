import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Push from "../components/push"
import EasySection from "../components/easy_section"
import ChromeSection from "../components/chrome_section"
import DomainSection from "../components/domain_section"
import UsersSection from "../components/users_section"
import DashboardSection from "../components/dashboard_section"
import DetailsSection from "../components/details_section"
import CallToActionSection from "../components/call_to_action_section"
import FaqSection from "../components/faq_section"

class Faq extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Privacy Policy" slug="/privacy/" />
        <div className="container terms">
          <h2>Privacy Policy</h2>
          <p>Last Modified: September 17, 2019</p>

          <h3>Information We Collect</h3>
          <p>There are three basic categories of information we collect:</p>
          <ul>
            <li>Information you choose to give us.</li>
            <li>Information we get when you use our services.</li>
            <li>Information we get from third parties.</li>
            <li>Here’s a little more detail on each of these categories.</li>
          </ul>

          <h3>Information You Choose to Give Us</h3>
          <p>
            When you interact with our services, we collect the information that you choose to share with us. For example,
            most
            of
            our services require you to set up a basic Hyperlink account, so we need to collect a few important details
            about
            you,
            such as: a unique username you’d like to go by, a password, an email address, a phone number, and your date of
            birth.
            To make it easier for others to find you, we may also ask you to provide us with some additional information that
            will
            be publicly visible on our services, such as profile pictures, a name, or other useful identifying information.
            Other
            services, such as commerce products, may also require you to provide us with a debit or credit card number and its
            associated account information.
    </p>

          <p>
            Of course, you’ll also provide us whatever information you send through our services, such as videos and Chats to
            your
            friends. Keep in mind that the users you send videos, Chats, and any other content to can always save that content
            or
            copy it outside the app. So, the same common sense that applies to the internet at large applies to Hyperlink
            as
            well:
            Don’t send messages or share content that you wouldn’t want someone to save or share.
    </p>

          <p>
            It probably goes without saying, but we’ll say it anyway: When you contact customer support or communicate with us
            in
            any other way, we’ll collect whatever information you volunteer.
    </p>

          <h3>Information We Get When You Use Our Services</h3>
          <p>
            When you use our services, we collect information about which of those services you’ve used and how you’ve used
            them.
            We might know, for instance, that you watched a particular video, saw a specific ad for a certain period of time,
            and
            sent a few videos to friends. Here’s a fuller explanation of the types of information we collect when you use our
            services:
    </p>

          <ul>
            <li>
              Usage Information. We collect information about your activity through our services. For example, we may collect
              information about:
        <ul>
                <li>how you interact with our services, such as which filters you view or apply to videos, which videos you
            watch, or which search queries you submit.</li>
                <li>how you communicate with other Hyperlink users, such as their names, the time and date of your
                  communications,
                  the
                  number
                  of messages you exchange with your friends, which friends you exchange messages with the most, and your
                  interactions
            with messages (such as when you open a message or capture a screenshot).</li>
              </ul>
              <li>
                Content Information. We collect content you create on our services, such as custom stickers, and information
                about
                the
                content you create or provide, such as if the recipient has viewed the content and the metadata that is provided
                with
        the content.</li>
              <li>
                Device Information. We collect information from and about the devices you use. For example, we collect:
        <ul>
                  <li>
                    information about your hardware and software, such as the hardware model, operating system version, device
                    memory,
                    advertising identifiers, unique application identifiers, apps installed, unique device identifiers, browser
                    type,
            language, battery level, and time zone;</li>
                  <li>information from device sensors, such as accelerometers, gyroscopes, compasses, microphones, and whether
                    you
                    have
            headphones connected; and</li>
                  <li>information about your wireless and mobile network connections, such as mobile phone number, service
                    provider, and
            signal strength.</li>
                </ul>
              </li>
              <li>
                Device Phonebook. Because Hyperlink is all about communicating with friends, we may—with your
                permission—collect
                information from your device’s phonebook.
      </li>
              <li>
                Camera and Photos. Many of our services require us to collect images and other information from your device’s
                camera
                and photos. For example, you won’t be able to send videos or upload photos from your camera roll unless we can
                access
                your camera or photos.
      </li>
              <li>
                Location Information. When you use our services we may collect information about your location. With your
                permission,
                we may also collect information about your precise location using methods that include GPS, wireless networks,
                cell
                towers, Wi-Fi access points, and other sensors, such as gyroscopes, accelerometers, and compasses.
      </li>
              <li>
                Information Collected by Cookies and Other Technologies. Like most online services and mobile applications, we
                may
                use
                cookies and other technologies, such as web beacons, web storage, and unique advertising identifiers, to collect
                information about your activity, browser, and device. We may also use these technologies to collect information
                when
                you interact with services we offer through one of our partners, such as advertising and commerce features. Most
                web
                browsers are set to accept cookies by default. If you prefer, you can usually remove or reject browser cookies
                through
                the settings on your browser or device. Keep in mind, though, that removing or rejecting cookies could affect
                the
                availability and functionality of our services.
      </li>
              <li>
                Log Information. We also collect log information when you use our website. That information includes, among
                other
        things:</li>
              <ul>
                <li>details about how you’ve used our services.</li>
                <li>device information, such as your web browser type and language.</li>
                <li>access times.</li>
                <li>pages viewed.</li>
                <li>IP address.</li>
                <li>identifiers associated with cookies or other technologies that may uniquely identify your device or browser.
        </li>
                <li>pages you visited before or after navigating to our website.</li>
              </ul>
              </li>
    </ul>

            <h3>Information We Collect from Third Parties</h3>
            <p>
              We may collect information about you from other users, our affiliates, and third parties. Here are a few examples:
    </p>
            <ul>
              <li>If you link your Hyperlink account to another service (like Snapchat or a third-party app), we may receive
                information
        from the other service, like how you use that service.</li>
              <li>Advertisers may let us know you installed a new app or game so we can determine whether we showed you an ad
                for
        it.</li>
              <li>If you interact with one of our advertisers, they may share information with us to help target or measure the
        performance of ads.</li>
              <li>If another user uploads their contact list, we may combine information from that user’s contact list with
                other
        information we have collected about you.</li>
            </ul>

            <h3>How We Use Information</h3>
            <p>
              What do we do with the information we collect? The short answer is: Provide you
              with
              an amazing set of products and services that we relentlessly improve. Here are the ways we do that:
    </p>
            <ul>
              <li>develop, operate, improve, deliver, maintain, and protect our products and services.</li>
              <li>send you communications, including by email. For example, we may use email to respond to support inquiries or
                to
                share
        information about our products, services, and promotional offers that we think may interest you.</li>
              <li>monitor and analyze trends and usage.</li>
              <li>personalize our services by, among other things, suggesting friends or profile information, or customizing the
                content
        we show you, including ads.</li>
              <li>contextualize your experience by, among other things, tagging your video content using your precise location
                information (if, of course, you’ve given us permission to collect that information) and applying other labels
                based
                on
        the content.</li>
              <li>provide and improve ad targeting and measurement, including through the use of your precise location
                information
                (again, if you’ve given us permission to collect that information), both on and off our services. See the
                Control
                Over
                Your Information section below for more information about Hyperlink’s advertising practices and your choices.
        enhance the safety and security of our products and services.</li>
              <li>verify your identity and prevent fraud or other unauthorized or illegal activity.</li>
              <li>use information we’ve collected from cookies and other technology to enhance our services and your experience
                with
        them.</li>
              <li>enforce our Terms of Service and other usage policies.</li>
            </ul>
            <p>
              We may also use information from Apple’s TrueDepth camera to improve the quality of Lenses. Information from the
              TrueDepth camera is used in real time—we don’t store this information on our servers or share it with third
              parties.
    </p>

            <h3>How We Share Information</h3>
            <p>We may share information about you in the following ways:</p>

            <ul>
              <li>With other Hyperlink users. We may share the following information with other Hyperlink users:
                information about you, such as your username, name, and profile picture.
              <ul>
                  <li>information about how you have interacted with our services, such as your Hyperlink “score,” the names
                    of
                    Hyperlink users
                    you are friends with, and other information that will help Hyperlink users understand your connections
                    with
                    others using
                    our services. For example, because it may not be clear whether a new friend request comes from someone you
                    actually
            know, we may share whether you and the requestor have Hyperlink friends in common.</li>
                  <li>any additional information you have directed us to share.</li>
                  <li>content you post or send. How widely your content is shared depends on your personal settings and the type
                    of service
            you are using.</li>
                </ul>
              </li>
              <li>
                With all Hyperlink users, our business partners, and the general public. We may share the following
                information
                with
                all
                Hyperlink users as well as with our business partners and the general public:
        <ul>
                  <li>public information like your name, username and profile pictures.</li>
                  <li>Video submissions that are set to be viewable by Everyone and any content that you submit to an inherently
                    public
                    service and other crowd-sourced services. This content may be viewed and shared by the public
                    at
                    large
                    both on and off our services, including through search results, on websites, in apps, and in online and
                    offline
            broadcasts.</li>
                </ul></li>
                <li>With third parties. We may share your information with the following third parties:
        <ul>
                    <li>With service providers. We may share information about you with service providers who perform services on
                      our
            behalf.</li>
                    <li>
                      With business partners. We may share information about you with business partners that provide services and
            functionality.</li>

                    <li>With third parties for legal reasons. We may share information about you if we reasonably believe that
                      disclosing
                      the
                      information is needed to:
            <ul>
                        <li>comply with any valid legal process, governmental request, or applicable law, rule, or regulation.
              </li>
                        <li>investigate, remedy, or enforce potential Terms of Service violations.</li>
                        <li>protect the rights, property, and safety of us, our users, or others.</li>
                        <li>detect and resolve any fraud or security concerns.</li>
                      </ul>
                      </li>
                      <li>
                        With third parties as part of a merger or acquisition. If Hyperlink gets involved in a merger, asset
                        sale,
                        financing,
                        liquidation or bankruptcy, or acquisition of all or some portion of our business to another company, we may
                        share
                        your
                        information with that company before and after the transaction closes.
                      </li>
                    </ul>
                    </li>
                    <li>We may also share with third parties—such as advertisers—aggregated, non-personally identifiable, or
                      de-identified
        information.</li>
                  </ul>

                  <h3>Third-Party Content and Integrations</h3>
                  <p>
                    Our services may also contain third-party links and search results, include third-party integrations, or offer a
                    co-branded or third-party-branded service. Through these links, third-party integrations, and co-branded or
                    third-party-branded services, you may be providing information (including personal information) directly to the
                    third
                    party, us, or both. You acknowledge and agree that we are not responsible for how those third parties collect or
                    use
                    your information. As always, we encourage you to review the privacy policies of every third-party service that
                    you
                    visit or use, including those third parties you interact with through our services.
      </p>

                  <h3>Analytics and Advertising Services</h3>
                  <p>Provided by Others</p>
                  <p>We may let other companies use cookies, web beacons, and similar technologies on our services. These companies
                    may
                    collect information about how you use our services over time and combine it with similar information from other
                    services and companies. This information may be used to, among other things, analyze and track data, determine
                    the
                    popularity of certain content, and better understand your online activity.
            
                    Additionally, some companies, including our affiliates, may use information collected on our services to measure
                    the
                    performance of ads and deliver more relevant ads on behalf of us or other companies, including on third-party
                    websites
                    and apps.
      </p>

                  <h3>Provided by Us</h3>
                  <p>We may collect information about your activity on third-party services that use cookies and other technologies
                    provided by us. We use this information to improve our advertising services, including measuring the performance
                    of
                    ads and showing you more relevant ads.
      </p>

                  <h3>Control over Your Information</h3>
                  <p>We want you to be in control of your information, so we provide you with the following tools.</p>
                  <ul>
                    <li>
                      Revoking permissions. If you let us use your information, you can always change your mind and simply revoke
                      your
                      permission by changing the settings on your device if your device offers those options. Of course, if you do
                      that,
          certain services may lose full functionality.</li>
                    <li>
                      Deletion. While we hope you’ll remain a lifelong Hyperlink User, if for some reason you ever want to delete
                      your
                      account,
                      email us. You can also delete some information in the app, like photos you’ve saved, video
                      submissions, and search history.
        </li>
                  </ul>

                  <h3>Children</h3>
                  <p>
                    Our services are not intended for—and we don’t direct them to—anyone under 13. And that’s why we do not
                    knowingly
                    collect personal information from anyone under 13. In addition, we may limit how we collect, use, and store
                    some
                    of
                    the information of EU users between 13 and 16. In some cases, this means we will be unable to provide certain
                    functionality to these users. If we need to rely on consent as a legal basis for processing your information
                    and
                    your
                    country requires consent from a parent, we may require your parent’s consent before we collect and use that
                    information.
      </p>

                  <h3>Revisions to the Privacy Policy</h3>
                  <p>
                    We may change this Privacy Policy from time to time. But when we do, we’ll let you know one way or another.
                    Sometimes,
                    we’ll let you know by revising the date at the top of the Privacy Policy that’s available on our website and
                    mobile
                    application. Other times, we may provide you with additional notice (such as adding a statement to our
                    websites’
                    homepages or providing you with an in-app notification).
      </p>
        </div>
      </Layout >
            );
          }
        }
        
        export default Faq
        
        export const pageQuery = graphql`
  query {
              site {
              siteMetadata {
              title
            }
            }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC }) {
              edges {
              node {
              excerpt
          fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
  }
  }
  `
